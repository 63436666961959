@import "../../App.scss";
#pending-container{
    justify-content: center;
    text-align: center;
    margin-top: 3em;
    img{
      width: 60px;
    }
    #pending-text{
        font-family: var(--text-font);
        font-weight: 700;
        margin: 1em 0em 2em 0em;
    }
}