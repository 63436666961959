#pending-container{
    justify-content: center;
    text-align: center;
    margin-top: 3em;
    img{
      width: 40px;
    }
    /*
    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
    }
    .lds-ripple div {
    position: absolute;
    border: 4px solid var(--primary-yellow);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
    }
    @keyframes lds-ripple {
        0% {
            top: 18px;
            left: 18px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 36px;
            height: 36px;
            opacity: 0;
        }
    }
    */
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 36px;
        height: 36px;
        margin: 4px;
        border: 4px solid var(--primary-yellow);
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--primary-yellow) transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    #pending-text{
        font-family: var(--text-font);
        font-weight: 700;
        margin: 1em 0em;
    }
     
}