@import "../../App.scss";
@import "./Tooltip.scss";
.card{
    position: fixed relative;
    left: 0;
    top: 10em;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    .changechaincard-container{
        z-index: 1;
        box-shadow: rgba(0, 0, 0, 0.10) 0px 3px 8px;
        border: 1px solid #D0CFCF;
        position: absolute;
        top: 5em;
        left: calc(100% - 25em);
        background-color: white;
        padding: 1.5em;
        border-radius: 10px;
        .changechaincard-text{
            font-weight: 700;
            margin-bottom: .5em;
        }
        #chain-opt-btn{
            display: flex;
            margin-top: .5em;
            align-items: center;
            outline: 0 !important;
            color:#6C757D;
            border: 2px solid #6C757D;
            border-radius: 38px;
            margin-right: 1em;
            background-color: white;
            img{
                width: 1.9em;
                margin: 0;
                padding: .1em;
            }
            #chain-opt-btn-text{ 
                display: flex !important;
                font-family: var(--text-font);
                font-size: 1em;
                font-weight: 700;
                padding: .1em 1em .1em .2em;
                margin-right: 1em;
                margin-left: .5em;
            }
            
        }
        #chain-opt-btn:hover{
            color: black;
            border: 2px solid black;
        }
    }
}
