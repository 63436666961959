@import "../../../App.scss";
.card-background{
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.card-container{
    width:40%;
    background-color: white;
    margin:15% auto;
    padding:3em;
    border-radius: 20px;
    font-family:var(--text-font);
    .title-row{
        display:flex;
        place-content: space-between;
        align-items: center;
        margin-bottom: 3em;
        #title{
            font-weight: 700;
            font-size: 1.5em;
        }
        #toggle-container{
            align-items: center;
            display:flex;
            float:right;
            flex-wrap: nowrap;
            flex-flow: row;
            #left-text{
                font-weight: 700;
                margin-right:1em;
            }
        }
    }
    #title2{
        font-weight: 700;
        font-size: 1.3em;
        margin-top: 3em;
        margin-bottom: 1.5em;
    }
    .asset-info{
        font-weight:700;
        display: grid;
        align-items: center;
        grid-template-columns: 30% 35% auto;
        grid-gap: 5%;
        margin-bottom: 1em;
        .longapy{
            font-size: .7em !important;
        }
    }
    .deposit-input{
        display: grid;
        grid-template-columns: 70% auto;
        grid-gap: 5%;
        margin: 2em 0 1.5em 0;
        #input-field{
            position: relative;
            align-items: center;
            #select{
                display: flex;
                align-items: center;
                position:absolute;
                cursor:pointer;
                font-weight: 700;
                z-index:1;
                top:13%;
                left: 3%;
                background-color: none;
                outline:none;
                border: none;
                padding: .8em 0em;
                font-size: .8em;
                img{
                    width:20px;
                    margin-right: 8px;
                }
                #downward-tri{
                    width: 10px !important;
                    margin-left: 8px;
                }
            }
            #deposit-input{
                display: inline-block;
                //width: 60%;
                width: calc(100% - 9em);
                padding-left: 9em;
            }
            
            .max-btn{
                cursor:pointer;
                text-decoration: underline;
                position:absolute;
                z-index: 1;
                top: 33%;
                left: 85%;
                border: none;
                background: none;
                font-weight: 700;
                font-size: .8em;
            }
            .max-btn:hover{
                color: var(--primary-yellow);
                
            }
        }
    }

    #bootstraping-anouncement{
        margin-top: 5em;
        font-size: .8em;
        font-weight: 700;
        text-align: left !important;
    }

    .align-right{
        text-align: right;
    }
    #withdraw{
        display: grid;
        grid-template-columns: 70% auto;
        grid-gap: 5%;
        //margin: 1em 0;
        margin: 2em 0 1.5em 0;
    }
    #exceed-warning{
        margin: 2em 0em;
        color: var(--primary-yellow);
        background-color: black;
        font-weight: 700;
    }
    #withdraw-ratio{
        display: grid;
        grid-template-columns: 21% 21% 21% auto;
        grid-gap: 5%;
        margin-bottom: 5em;
    }
    #claim-all-btn{
        cursor:pointer;
        width: 100%;
        padding: 1em;
        margin: 1em 0 1em 0;
    }
    .samller-btn{
        margin-left: .6em;
    }
    input[type=text]{
        border: solid 1px #C4C4C4;
        border-radius: 10px;
        background-color:white;
        outline: none; //change to primary color later
        padding: 1em;
        font-size: 1em;
        font-weight: 700;
    }
    .btn-1{
        cursor:pointer;
        color:black;
        border: solid 2px black;
        border-radius: 10px;
        font-size: 1em;
        font-weight: 700;
        background-color: white;
        padding: .3em 1em;
        outline: none;
    }
    .btn-2{
        cursor:pointer;
        color:white;
        border: none;
        border-radius: 10px;
        font-size: 1em;
        font-weight: 700;
        background-color: black;
        padding: .3em 1em;
        outline: none;
    }
    .btn-1:hover{
        //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        color:var(--primary-yellow);
        border: solid 2px var(--primary-yellow);
    }
    .btn-2:hover{
        background-color: var(--primary-yellow);
    }
    .disappear{
        display: none !important;
    }
}

.progress-bar-container{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2em;
    #text{
        font-family: var(--text-font);
        color: #717071;
        font-weight: 400;
        margin-bottom: .5em;
    }
    .light-grey{
        background-color: var(--primary-grey);
        border: solid 1px #C4C4C4;
        border-radius: 20px;
        height: .5em;
        width: 700%;
        .yellow{
            background-color: var(--primary-yellow);
            border-radius: 20px;
        }
    }

}

#dropdown-container{
    position: absolute;
    display: block;
    flex-wrap: nowrap;
    border: solid 1px #c4c4c4;
    border-radius: 10px;
    background-color: white;
    top:5%;
    z-index: 1;
}

#option-container{
    width: 9em;
    padding: 1em 0em;
    display: flex;
    font-size:.8em;
    font-weight: 700;
    align-items: center;
    img{
        width:20px;
        margin: 0 8px;
    }

}
#option-container:hover{
    background-color: #F8F9FA;
    border-radius: 10px;
}

#slipage-container{
    position: relative;
    display: flex;
    float: right;
    place-content: space-between;
    align-items: center;
    font-size: .8em;
    margin-bottom: 0em;
    overflow: hidden; 
    #slipage-btn{
        width:20%;
        border: solid 1px #c4c4c4;
        background-color: white;
        border-radius: 10px;
    }
    #slipage-input{
        width:10%;
        padding: 0.1em 1em;
        font-weight: 400;
        //text-align: center;
    }
    #slipage-input:focus{
        border: solid 1px var(--primary-yellow);
    }
    .active{
        background-color: var(--primary-yellow) !important;

    }
    #percentage-tag{
        position:absolute;
        z-index: 1;
        top:16%;
        left:95%;
    }
}