@import "../../App.scss";
.notfound-container{
    padding-top: 10em;
    height:85vh;
    display: block;
    text-align: center;
    align-content: center;
    font-family: var(--text-font);
    font-size: 1.3em;
    font-weight: 700;
    img{
        display: block;
        align-self: center;
        margin-left: auto;
        margin-right: auto;
    }
    p{
        display: block;
    }
}