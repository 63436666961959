@import "../../App.scss";
.dashboard-container{
    height: 300vh;
    font-family: var(--text-font);
    .vault-btn-container{
        padding: 2em 0 5em 0;
        display: flex;
        flex-wrap:wrap;
        place-content: space-between;
    }
    .iv-container{
        margin-top: 6em !important;
    }
    .info-container{
        //display: flex;
        //flex-wrap:wrap;
        //place-content: space-between;
        margin-top: 3em;
        .h1{
            display: flex;
            width: 100%;
            font-size: 1.5em;
            font-weight: 700;
            margin-bottom: 1.5em;
        }
        .h2{
            display: flex;
            width: 100%;
            font-size: 1.3em;
            font-weight: 700;
            margin-bottom: 1em;
            color: var(--primary-yellow);
        }
        .vault-info-container{
            display: flex;
            flex-wrap:wrap;
            place-content:space-between;

        }
    }
    .vault-function-container{
        .function-title{
            //display: flex;
            font-size: 1em;
            font-weight: 700;
            color: #a09f9f;
            margin-bottom: .8em;
            margin-top: 1em;
            border-bottom: 2px solid #ece8e8;
        }
        .vault-function{
            font-family:var(--text-font);
            font-weight: 700;
            display: flex;
            place-content: space-between;
            align-items: stretch;
            margin-bottom: .5em;
            input[type=text]{
                border: solid 1px #C4C4C4;
                border-radius: 10px;
                background-color:white;
                outline: none; //change to primary color later
                padding: 0.5em ;
                font-size: 1em;
                font-weight: 700;
            }
            .vault-function-name{
                font-size: 1.3em;
                margin: .5em 0;
            }
            .btn-2{
                cursor:pointer;
                color:white;
                border: none;
                border-radius: 10px;
                font-size: 1em;
                font-weight: 700;
                background-color: black;
                padding: .5em 1em;
                outline: none;
            }
            .btn-2:hover{
                background-color: var(--primary-yellow);
            }   
        }
        .function-result{
            margin-bottom: 2em;
            font-family:var(--text-font);
            font-weight: 700;
        }
        .addresslist{
            display: flex;
            flex-wrap: wrap;
            flex-flow: column;
            width: 60%;
            //margin-top: 1em;
        }
    }
}

.vault-btn{
    display:inline-block;
    width:15em;
    background-color: white;
    padding: 1em 2em;
    border-radius: 20px;
    font-weight:700;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .vault-title{
        font-size:1.5em;
        margin-bottom: 1em;
    }
    .address, .asset{
        margin-bottom: .8em;
        //text-align: center;
    }
}
.vault-btn:hover{
    box-shadow: rgba(249, 174, 59, 0.24) 0px 3px 8px;
}

.info{
    display: inline-block;
    width: 30%;
    font-family:var(--text-font);
    font-weight: 700;
    margin-bottom: 2em;
    .info-title{
        font-size: 1em;
        color: #a09f9f;
        margin-bottom: .8em;
        border-bottom: 2px solid #ece8e8;
    }
    .info-content{
        //text-align: center;
        font-size: 1.3em;
    }
}
.address{
    width: 65% !important;
}
.input-address{
    width: 50% !important;
}
.btn-1{
    cursor:pointer;
    color:black;
    border: solid 2px black;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 700;
    background-color: white;
    padding: .3em 1em;
    outline: none;
}

.btn-1:hover{
    //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color:var(--primary-yellow);
    border: solid 2px var(--primary-yellow);
}
.btn-2{
    cursor:pointer;
    color:white;
    border: none;
    border-radius: 10px;
    font-size: 1em;
    font-weight: 700;
    background-color: black;
    padding: .3em 1em;
    outline: none;
}
.btn-2:hover{
    background-color: var(--primary-yellow);
}