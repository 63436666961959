@import "../Vault/css/AssetCard.scss";

.connect-card{
    text-align: center;
    img{
        width: 2em;
    }
    #connect-text{
        font-family: var(--text-font);
        font-weight: 700;
        margin: 1em 0em;
        line-height: 1.8em;
    }
}