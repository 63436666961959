@import "../../App.scss";

.homepage{
    background-color: var(--primary-grey) !important;
    border-bottom: none !important;
}

#navbar-container{
    background-color: #FFFFFF;
    border-bottom: 1px solid #D0CFCF;
    display: flex;
    place-content: space-between;
    align-items: center;

    #list-btn{
        display: block !important;
        cursor: pointer;
    }

    #logo-img{
        display: block !important;
        width: 2.5em;
    }

    #navbar-title{
        font-family: var(--logo-font);
        font-size: 1.3em;
        font-weight: 700;
        width: 30%;
    }
    h1{
        padding: 0;
        margin: 0;
    }
    .btn-container{
        display:flex;
        #chain-btn{
            display: flex;
            align-content: center;
            outline: 0 !important;
            color:var(--primary-yellow);
            border: 2px solid var(--primary-yellow);
            border-radius: 38px;
            margin-right: .5em;
            img{
                width: 1.9em;
                margin: 0;
                padding: .1em;
            }
            #chain-btn-text{ 
                display: none;
            }
            
        }
        #chain-btn:hover{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        #metamask-btn{
            color:white;
            border:2px solid black;
            border-radius: 38px;
            font-family: var(--text-font);
            font-size: 1em;
            font-weight: 700;
            background-color: #000000;
            padding: .5em 1em;
            outline: none;
        }
        #metamask-btn:hover{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

    }
    
    .grey{
        background-color: #c4c4c4 !important;
        border:2px solid #c4c4c4 !important;
    }
    .yellow{
        background-color: var(--primary-yellow) !important;
    }
    
}

@media only screen and (min-width:726px){
    .homepage{
        background-color: var(--primary-grey) !important;
        border-bottom: none !important;
    }
    
    #navbar-container{
        background-color: #FFFFFF;
        border-bottom: 1px solid #D0CFCF;
        display: flex;
        place-content: space-between;
        align-items: center;
        #list-btn{
            display: none !important;
        }
        #logo-img{
            display: none !important;
        }
        #navbar-title{
            font-family: var(--logo-font);
            font-size: 1.5em !important;
            font-weight: 700;
        }
        h1{
            padding: 0;
            margin: 0;
        }
        .btn-container{
            display:flex;
            #chain-btn{
                display: flex;
                align-items: center;
                outline: 0 !important;
                color:var(--primary-yellow);
                border: 2px solid var(--primary-yellow);
                border-radius: 38px;
                margin-right: 1em;
                background-color: white;
                img{
                    width: 1.9em;
                    margin: 0;
                    padding: .1em;
                }
                #chain-btn-text{ 
                    display: flex !important;
                    font-family: var(--text-font);
                    font-size: 1em;
                    font-weight: 700;
                    padding: .1em 1em .1em .2em;
                    margin-right: 1em;
                    margin-left: .5em;
                }
                
            }
            #chain-btn:hover{
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
            #metamask-btn{
                color:white;
                border:2px solid black;
                border-radius: 38px;
                font-family: var(--text-font);
                font-size: 1em;
                font-weight: 700;
                background-color: #000000;
                padding: .5em 1em;
                outline: none;
            }
            #metamask-btn:hover{
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            }
    
        }
        
        .grey{
            background-color: #c4c4c4 !important;
            border:2px solid #c4c4c4 !important;
        }
        .yellow{
            background-color: var(--primary-yellow) !important;
        }
        
    }
}