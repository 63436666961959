@import "../../../App.scss";
.container {
    align-content: center;
    display: inline-block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: .8em;
    font-family: var(--text-font);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 12px;
    width: 12px;
    border: solid 1px #c4c4c4;
    border-radius: 5px;
    background-color: var(--primary-grey);
  }
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .container input:checked ~ .checkmark {
    background-color: var(--primary-yellow);
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }