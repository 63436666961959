@import "../../App.scss";
html,body{
    margin: 0;
    overflow-x: initial !important;
    //overflow-y: hidden;
}
.homepage-container{
    position: relative;
    overflow-y: hidden;
    border-left: 1px solid #D0CFCF;
    height: 110vh;
    h1{
        margin:1em 0em 0.5em 0;
        padding: 0;
        font-size: 56px;
        font-family: var(--logo-font);
        font-weight: 700px;
    }
    .intro-text{
        font-family: var(--text-font);
        font-weight: 700;
        font-size: 1.2em;
        line-height: 1.5em;
        margin-bottom: 2em;
    }
    .btn-2{
        cursor:pointer;
        width: 6em;
        color:white;
        border: none;
        border-radius: 20px;
        font-size: 1em;
        font-weight: 700;
        background-color: var(--primary-yellow);
        padding: .5em 1em;
        outline: none;
        .text{
            color: white;
        }
      }
    .btn-2:hover{
        background-color: black;
    }

    #longmon-container{
        position:absolute;
        z-index: 1;
        top: 40%;
        left: 50%;
        #longmon{
            width: 300px;
            height: auto;
            overflow-x: hidden;
        }
    }
    #cloud1{
        z-index: 0;
        position: absolute;
        width: 10em;
        left:40%;
        top: 50%;
    }
    #cloud2{
        z-index: 2;
        position: absolute;
        width: 15em;
        left: 60%;
        top: 60%;
    }

}

@media only screen and (min-width:726px){
    .homepage-container{
        position: relative;
        overflow-y: hidden;
        border-left: 1px solid #D0CFCF;
        height: 110vh;
        h1{
            margin:1em 0em 0.5em 0;
            padding: 0;
            font-size: 56px;
            font-family: var(--logo-font);
            font-weight: 700px;
        }
        .intro-text{
            font-family: var(--text-font);
            font-weight: 700;
            font-size: 1.2em;
            line-height: 1.5em;
            margin-bottom: 2em;
        }
        .btn-2{
            cursor:pointer;
            width: 6em;
            color:white;
            border: none;
            border-radius: 20px;
            font-size: 1em;
            font-weight: 700;
            background-color: var(--primary-yellow);
            padding: .5em 1em;
            outline: none;
            .text{
                color: white;
            }
          }
        .btn-2:hover{
            background-color: black;
        }
    
        #longmon-container{
            position:absolute;
            z-index: 1;
            top: 30%;
            left: 50%;
            #longmon{
                width: 500px;
                height: auto;
                overflow-x: hidden;
            }
        }
        #cloud1{
            z-index: 0;
            position: absolute;
            width: 20em;
            left:40%;
            top: 40%;
        }
        #cloud2{
            z-index: 2;
            position: absolute;
            width: 30em;
            left: 70%;
            top: 60%;
        }
    
    }
}